import { Table, Input, Space } from "antd";
import React, { useState } from "react";
import { useGetMemberQuery } from "../features/api/userAPI";
import { Navigate } from "react-router-dom";
import Area from "../components/Area";
import { useSelector } from "react-redux";
import ContentWrap from "../components/ContentHead";

function AddressBook() {
  const { Search } = Input
  const columns = [
    { title: "公司名称", dataIndex: "name", key: "name" },
    { title: "区域", dataIndex: "area_display", key: "area_display" },
    { title: "地址", dataIndex: "address_work", key: "address_work" },
    { title: "法人", dataIndex: "boss", key: "boss" },
    { title: "联系人", dataIndex: "contract", key: "contract" },
    { title: "办公室电话", dataIndex: "tel", key: "tel" },
  ]
  const [companyName, setCompanyName] = useState("")
  const searchArea = useSelector(state => state.user.searchArea)
  const rst = useGetMemberQuery({ page: 1, companyName, area: searchArea })
  const { data, isLoading, isError, error } = rst
  const errorStatus = error?.status
  if (isError && errorStatus === 401) {
    return (
      <Navigate to='/login' replace />
    )
  } else {
    const dataSource = data?.results
    return (
      <ContentWrap breadItems={[
        { title: "会员通讯录" },
        { title: "会员通讯录" }
      ]}>
        <Space size={"middle"} className="mb-4">
          <Area className="w-72" />
          <Search
            placeholder="请输入公司名称..."
            allowClear
            size="large"
            enterButton
            onSearch={(value) => {
              setCompanyName(value)
            }}
            className="w-60 name-search"
            loading={isLoading}
          />
        </Space>
        <Table
          loading={isLoading}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
        />
      </ContentWrap>
    )
  }
}

export default AddressBook
