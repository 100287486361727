import { Button, Input, Space, message } from "antd";
import Form from "antd/es/form/Form";
import React, { useState } from "react";
import { useChangePasswordMutation } from "../features/api/userAPI";
import { useDispatch } from "react-redux";
import { cleanupUserToken } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import ContentWrap from "../components/ContentHead";
const ChangePassword = () => {
  const [formRef] = Form.useForm();
  const [error, setError] = useState('');
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const requiredRule = {
    required: true,
    message: '这个字段是必填的'
  }

  const onFinish = (value) => {
    changePassword({ value }).then((result) => {
      if (result.error) {
        const error = result.error.data?.message
        setError(error)
      } else {
        // 密码修改成功
        message.success("请重新登录!")
        dispatch(cleanupUserToken())
        navigate("/login")
      }
    })
  }

  const passwordValidator = (_, value) => {
    // 密码复杂性要求：至少6个字符，包含至少一个数字和一个字母
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;

    if (value && !passwordRegex.test(value)) {
      return Promise.reject('密码需要至少6个字符的“数字”和“字母”组成');
    }

    return Promise.resolve();
  };

  const confirmPasswordValidator = (_, value) => {
    const password = formRef.getFieldValue('newPassword')
    if (value && value !== password) {
      return Promise.reject('两次输入密码不一致');
    }
    return Promise.resolve();
  };

  const handleOldPasswordChange = () => {
    setError(''); // 清空错误信息
    // formRef.validateFields(['oldPassword']); // 重新校验旧密码字段
  };

  return (
    <ContentWrap breadItems={[{ title: "企业信息" }, { title: "修改密码" }]}>
      <Form
        form={formRef}
        className="max-w-2xl mt-10"
        labelCol={{ span: 6 }}
        wrapperCol={{
          span: 14
        }}
        onFinish={onFinish}
      >
        <Form.Item
          rules={[requiredRule]}
          className="mb-10"
          label="老密码"
          name="oldPassword"
        >
          <Input.Password
            autoFocus
            autoComplete="current-password"
            onChange={handleOldPasswordChange}
            placeholder="请输入目前的登录密码" />
        </Form.Item>
        <Form.Item
          rules={[requiredRule,
            { validator: passwordValidator },
          ]}
          name="newPassword"
          label="新密码">
          <Input.Password
            autoComplete="new-password"
            placeholder="请输入修改后的密码" />
        </Form.Item>
        <Form.Item
          rules={[requiredRule,
            { validator: confirmPasswordValidator },]}
          name="confirmPassword"
          label="确认密码">
          <Input.Password
            autoComplete="new-password"
            placeholder="请将修改后的密码再输入一次" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 6,
          }}
        >
          {error && <div className="text-red-600 font-medium">
            {error}
          </div>}
          <Space
            className="mt-4"
          >
            <Button loading={isLoading} type="primary" htmlType="submit">
              提交
            </Button>
            <Button htmlType="reset">取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </ContentWrap>
  )
}
export default ChangePassword
