import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, DatePicker, InputNumber, Tag } from "antd";
import React, { useState } from "react";
import { useGetExcellentProjectmanagerDetailQuery, useGetUserQuery, useAddExcellentProjectmanagerMutation, useUpdateExcellentProjectmanagerMutation, BASE_URL, useGetMetaInfoQuery } from "../../features/api/userAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import dayjs from 'dayjs';
import { load } from "react-cookies";
const { Title } = Typography
const ExcellentProjectmanagerAdd = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()
  const { data: member } = useGetUserQuery()
  const { data, isLoading } = useGetExcellentProjectmanagerDetailQuery({ id })

  const [addExcellentProjectmanager, { isLoading: addIsLoading }] = useAddExcellentProjectmanagerMutation()
  const [form] = useForm()
  const [updateExcellentProjectmanager, { isLoading: updateIsLoading }] = useUpdateExcellentProjectmanagerMutation()
  const { data: metaData, isLoading: isGetMetaInfoLoading } = useGetMetaInfoQuery()
  const [fileList, setFileList] = useState([])
  const [photoList, setPhotoList] = useState([])
  const [totalThisYearPrice, setTotalThisYearPrice] = useState(0)
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [mustRequiredRule, setMustRequiredRule] = useState({ required: true, message: " " })
  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredPhotoRule, setRequiredPhotoRule] = useState({ required: true, message: "这个字段是必须的" })
  useEffect(() => {
    const attached = data?.attached
    if (attached) {
      const fileList = [{ uid: data.attached_name, name: data.attached_name, status: 'done', url: attached }]
      setFileList(fileList);
    }
    const mproject_years = data?.project_years
    if (mproject_years) {
      const temp = mproject_years.reduce((sum, dict) => sum + (parseFloat(dict.price) || 0), 0)
      setTotalThisYearPrice(temp)
    }

    const photo = data?.photo
    if (photo && photo.length > 0) {
      const photoDic = photo[0]
      const _photoList = [{ uid: photoDic.id, name: "0", status: 'done', url: photoDic.photo, response: { id: photoDic.id } }]
      setPhotoList(_photoList)
    }
  }, [data]);
  // const totalThisYearPrice = totalThisYearPriceList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const breadItmes = [
    { title: "评优评先" },
    { title: "优秀项目经理（建造师）", path: "/excellentprojectmanager" },
    { title: id ? "修改" : "新增" }
  ]
  if (data?.status === 1) {
    navigate("/excellentprojectmanager", { replace: true })
  }

  const setPhotoAndAttachedRequired = () => {
    if (fileList && fileList.length > 0) {
      setRequiredFileRule({ ...requiredFileRule, required: false })
    } else {
      setRequiredFileRule({ ...requiredFileRule, required: true })
    }
    if (photoList && photoList.length > 0) {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
    } else {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: true })
    }
  }

  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1 && id) {
      setRequiredRule({ ...requiredRule, required: true })
      setPhotoAndAttachedRequired()
    } else {
      navigate("/excellentprojectmanager", { replace: true })
    }
  }

  const onThisYearPriceChange = () => {
    const projectYears = form.getFieldValue("project_years")
    const temp = projectYears.reduce((sum, dict) => sum + (parseFloat(dict.price) || 0), 0)
    setTotalThisYearPrice(temp)
  }

  const onFinish = (fieldsValue) => {
    const birthday = fieldsValue['birthday'] ? fieldsValue['birthday'].format('YYYY-MM-DD') : ""

    let photo_string = ""
    if (photoList && photoList.length > 0) {
      const response = photoList[0]?.response
      photo_string = response.id
    }
    const values = {
      ...fieldsValue,
      'birthday': birthday,
      'project_years_strings': JSON.stringify(fieldsValue['project_years']),
      photo_string
    }
    const formData = new FormData()
    for (var key in values) {
      const value = values[key] || ""
      formData.append(key, value)
    }
    const tempUploadFile = values?.tempUploadFile
    if (tempUploadFile && tempUploadFile.length > 0) {
      formData.append("attached", tempUploadFile[0].originFileObj)
    }
    if (id) {
      updateExcellentProjectmanager({ formData, id }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    } else {
      addExcellentProjectmanager({ formData }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图片
      </div>
    </div>
  )

  const companyName = id ? data?.company_name : member.name
  const title = id ? "修改 优秀项目经理（建造师）申请表" : "新增 优秀项目经理（建造师）申请表"
  const mID = data?.id
  const memberID = id ? data?.member : member.id
  const status = id ? data?.status : 0
  const addressWork = id ? data?.company_address : member.address_work
  const year = id ? data?.year : state.year
  const upload_photo_url = `${BASE_URL}/api/photoexcellentprojectview/`
  const userInfo = load("userInfo")
  const token = userInfo?.token
  console.log("@qy", userInfo?.username);

  const formListSpans = [2, 5, 4, 4, 4, 3, 2]
  const project_years = id ? data?.project_years : [null]
  const application_type = metaData?.application_type
  // const temp = project_years.reduce((sum, dict) => sum + (parseFloat(dict.price_this_year) || 0), 0);
  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || isGetMetaInfoLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={mID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider orientation="left">一、基本情况</Divider>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                label="姓&emsp;&emsp;名"
                initialValue={data?.name}
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"sex"}
                label="性别"
                initialValue={data?.sex}
                rules={[requiredRule]}
              >
                <Select
                  options={[{ value: "男", label: "男" }, { value: "女", label: "女" }]}
                  placeholder="请选择性别"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.birthday ? dayjs(data?.birthday, "YYYY-MM-DD") : ""}
                name={"birthday"}
                label="出生年月"
                rules={[requiredRule]}
              >
                <DatePicker format={"YYYY-MM-DD"} className="w-full" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                initialValue={data?.ethnicity}
                name={"ethnicity"}
                label="民族"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.education}
                name={"education"}
                label="文化程度"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.job_title}
                name={"job_title"}
                label="职称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.political_background}
                name={"political_background"}
                label="政治面貌"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.position}
                name={"position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.construction_cert_level}
                name={"construction_cert_level"}
                label="建造师资质等级"
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={[
                    { value: "园林中级工程师", label: "园林中级工程师" },
                    { value: "园林高级工程师", label: "园林高级工程师" },
                    { value: "建筑一级", label: "建筑一级" },
                    { value: "建筑二级", label: "建筑二级" },
                    { value: "公路一级", label: "公路一级" },
                    { value: "公路二级", label: "公路二级" },
                    { value: "水利水电一级", label: "水利水电一级" },
                    { value: "水利水电二级", label: "水利水电二级" },
                    { value: "市政一级", label: "市政一级" },
                    { value: "市政二级", label: "市政二级" },
                    { value: "机电一级", label: "机电一级" },
                    { value: "机电二级", label: "机电二级" },
                    { value: "矿业一级", label: "矿业一级" },
                    { value: "矿业二级", label: "矿业二级" },
                    { value: "铁路一级", label: "铁路一级" },
                    { value: "民航机场一级", label: "民航机场一级" },
                    { value: "港口与航道一级", label: "港口与航道一级" },
                    { value: "通信与广电一级", label: "通信与广电一级" },
                  ]}
                  placeholder="请选择建造师资质等级"
                />
                {/* <Input type="text1" className="ant-form-text" /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.construction_cert_number}
                name={"construction_cert_number"}
                label="资质证书编号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.id_card}
                name={"id_card"}
                label="身份证号码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.phone}
                name={"phone"}
                label="联系电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                initialValue={companyName}
                name={"company_name"}
                label="工作单位"
                rules={[requiredRule]}
              >
                <Input type="text1" readOnly className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={addressWork}
                name={"company_address"}
                label="工作单位地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.work_time}
                name={"work_time"}
                label="担任项目经理时间"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.zipcode}
                name={"zipcode"}
                label="邮政编码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业类型"
                rules={[requiredRule]}
                extra="申报类型要与企业类型一致"
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={application_type}
                  placeholder="请选择企业类型"
                />
              </Form.Item>
            </Col>
            {/* textarea */}
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"training_class"}
                initialValue={data?.training_class}
                label="近三年参加过何种项目经理业务培训班">
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">二、近二年完成（或在建）工程项目一览表</Divider>
          <Row gutter={[16, 4]}>
            <Col className="text-center" span={formListSpans[0]}>序号</Col>
            <Col span={formListSpans[1]}>项目名称</Col>
            <Col span={formListSpans[2]}>建筑面积: <b>平方米</b></Col>
            <Col span={formListSpans[3]}>合同（决算）造价：<b>万元</b></Col>
            <Col span={formListSpans[4]}>竣工质量等级</Col>
            <Col span={formListSpans[5]}>安全情况</Col>
            <Col span={formListSpans[6]}>&nbsp;</Col>
          </Row>
          <Form.List
            name="project_years"
            initialValue={project_years}
          >
            {(fields, { add, remove }, { errors }) => (
              <Row gutter={[10, 16]} align={"middle"}>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Col className="text-center" span={formListSpans[0]}>
                        {index + 1}
                        {/* <Form.Item hidden name={[name, "excellentprojectmanager"]} initialValue={mID} noStyle>
                          <Input type="text1"></Input>
                        </Form.Item> */}
                      </Col>
                      <Col span={formListSpans[1]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "name"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpans[2]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "area"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpans[3]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "price"]}
                        >
                          <InputNumber onChange={onThisYearPriceChange} />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpans[4]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          name={[name, "honor"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpans[5]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "safe"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpans[6]}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button "
                            onClick={() => remove(name)}
                          />
                        ) : null}
                      </Col>
                    </React.Fragment >
                  )
                })}

                <Col span={23}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{
                        width: '100%',
                      }}
                      icon={<PlusOutlined />}
                    >
                      增加工程
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </Col>

              </Row>
            )}
          </Form.List>
          <Row className="mb-10">
            <Col span={24}>
              <Space size={"large"}>
                <span>提示: {data?.year}年完成工作量总计: <b>{totalThisYearPrice}</b> 万元</span>
                {totalThisYearPrice >= 200 ? (<Tag color="green">&gt;=200万</Tag>) : (<Tag color="gold">不足200万</Tag>)}
              </Space>
            </Col>
            <Col span={24}>
              <div className="mt-3">近两年内有完成两项工程合同造价各600万元以上或一项工程合同造价在800万元以上工程（园林为完成两项工程合同造价各100万元以上或一项工程合同造价在200万元以上）</div>
            </Col>
          </Row>
          <Divider orientation="left">三、工程项目管理先进经验和先进事迹</Divider>
          <Row>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award1_last_year"}
                initialValue={data?.award1_last_year}
                label={`${year - 1}获得质量、安全奖项名称`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award1_this_year"}
                initialValue={data?.award1_this_year}
                label={`${year}获得质量、安全奖项名称`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award2_last_year"}
                initialValue={data?.award2_last_year}
                label={`${year - 1}获得其他荣誉名称`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award2_this_year"}
                initialValue={data?.award2_this_year}
                label={`${year}获得其他荣誉名称`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"advanced_experience"}
                initialValue={data?.advanced_experience}
                label={`工程项目管理先进经验和先进事迹(500字左右)`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left"></Divider>

          <Form.Item
            rules={[requiredRule]}
            initialValue={id ? data?.enterprise_opinion : "同意上报"}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="请上传PDF格式的文件，按照《申报材料装订顺序》的要求编写"
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              console.log("@664", rst);
              return rst
            }}
            rules={[requiredFileRule]}
          >
            <Upload
              fileList={fileList}
              onChange={(info) => {
                console.log("@637", info);
                const fileList = info.fileList || []
                if (fileList && fileList.length > 0) {
                  setFileList([...info.fileList]);
                } else {
                  setFileList(null)
                }
              }}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} 不是PDF文件`)
                  return Upload.LIST_IGNORE
                }
                return false
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="photo"
            label="电子照片"
            extra="请使用1寸照片，宽：300px，高：450px"
            rules={[requiredPhotoRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {
              console.log("@11", value);
              const fileList = value?.photoList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              console.log("@11", rst);
              return rst
            }}
          >
            <Upload
              name="photo"
              action={upload_photo_url}
              headers={{ Authorization: `Token ${token}`, }}
              fileList={photoList}
              onChange={({ fileList: newFileList }) => {
                return setPhotoList(newFileList)
              }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error(`${file.name} 不是图片文件`)
                  return Upload.LIST_IGNORE
                }
              }}
              maxCount={1}
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              onPreview={() => { console.log(photoList); return false }}
            >
              {photoList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            className="text-center"
          >
            <Space size={"large"}>
              <Button size="large" onClick={() => {
                setRequiredRule({ ...requiredRule, required: false })
                setRequiredFileRule({ ...requiredFileRule, required: false })
                setMustRequiredRule({ ...mustRequiredRule, required: false })
                setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
                form.setFieldValue("status", 0)
                setTimeout(() => {
                  form.submit()
                }, 100)
              }} loading={updateIsLoading || addIsLoading} >
                保存
              </Button>
              <Popconfirm
                title="提醒"
                description="提交后表单无法修改，您确认要提交吗？"
                onConfirm={() => {
                  setMustRequiredRule({ ...mustRequiredRule, required: true, })
                  setPhotoAndAttachedRequired()
                  form.setFieldValue("status", 1)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                }}
                okText="确定"
                cancelText="取消"
              >

                <Button size="large" loading={updateIsLoading || addIsLoading} type="primary">提交</Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form >
      }
    </ContentWrap >
  )
}

export default ExcellentProjectmanagerAdd
