import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, DatePicker } from "antd";
import React, { useState } from "react";
import { useGetSuccessfulEntrepreneurDetailQuery, useGetUserQuery, useAddSuccessfulEntrepreneurMutation, useUpdateSuccessfulEntrepreneurMutation, BASE_URL, useGetMetaInfoQuery } from "../../features/api/userAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import dayjs from 'dayjs';
import { load } from "react-cookies";
const { Title } = Typography
const SuccessfulEntrepreneurAdd = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()
  const { data: member } = useGetUserQuery()
  const { data, isLoading } = useGetSuccessfulEntrepreneurDetailQuery({ id })

  const [addSuccessfulEntrepreneur, { isLoading: addIsLoading }] = useAddSuccessfulEntrepreneurMutation()
  const { data: metaData, isLoading: isGetMetaInfoLoading } = useGetMetaInfoQuery()
  const [form] = useForm()
  const [updateSuccessfulEntrepreneur, { isLoading: updateIsLoading }] = useUpdateSuccessfulEntrepreneurMutation()
  const [fileList, setFileList] = useState([])
  const [photoList, setPhotoList] = useState([])
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredPhotoRule, setRequiredPhotoRule] = useState({ required: true, message: "这个字段是必须的" })

  if (data?.status === 1) {
    navigate("/advancedenterprise", { replace: true })
  }

  useEffect(() => {
    const attached = data?.attached
    const photo = data?.photo
    if (attached) {
      const fileList = [{ uid: data.attached_name, name: data.attached_name, status: 'done', url: attached }]
      setFileList(fileList);
    }
    if (photo && photo.length > 0) {
      const photoDic = photo[0]
      const _photoList = [{ uid: photoDic.id, name: "0", status: 'done', url: photoDic.photo, response: { id: photoDic.id } }]
      setPhotoList(_photoList)
    }
  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "优秀企业家", path: "/successfulentrepreneur" },
    { title: id ? "修改" : "新增" }
  ]

  const setPhotoAndAttachedRequired = () => {
    if (fileList && fileList.length > 0) {
      setRequiredFileRule({ ...requiredFileRule, required: false })
    } else {
      setRequiredFileRule({ ...requiredFileRule, required: true })
    }
    if (photoList && photoList.length > 0) {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
    } else {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: true })
    }
  }

  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1 && id) {
      setRequiredRule({ ...requiredRule, required: true })
      setPhotoAndAttachedRequired()
    } else {
      navigate("/successfulentrepreneur", { replace: true })
    }
  }

  const onFinish = (fieldsValue) => {

    const birthday = fieldsValue['birthday'] ? fieldsValue['birthday'].format('YYYY-MM-DD') : ""
    let photo_string = ""
    if (photoList && photoList.length > 0) {
      const response = photoList[0]?.response
      photo_string = response.id
    }
    const values = {
      ...fieldsValue,
      'birthday': birthday,
      photo_string
    }
    const formData = new FormData()
    for (var key in values) {
      const value = values[key] || ""
      formData.append(key, value)
    }
    const tempUploadFile = values?.tempUploadFile
    if (tempUploadFile && tempUploadFile.length > 0) {
      formData.append("attached", tempUploadFile[0].originFileObj)
    }
    if (id) {
      updateSuccessfulEntrepreneur({ formData, id }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    } else {
      addSuccessfulEntrepreneur({ formData }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图片
      </div>
    </div>
  )
  const companyName = id ? data?.company_name : member.name
  const title = id ? "修改 优秀企业家申请表" : "新增 优秀企业家申请表"
  const mID = data?.id
  const memberID = id ? data?.member : member.id
  const status = id ? data?.status : 0
  const addressWork = id ? data?.company_address : member.address_work
  const year = id ? data?.year : state.year
  const upload_photo_url = `${BASE_URL}/api/photo/`
  const main_project_level = metaData?.main_project_level
  const application_type = metaData?.application_type
  const userInfo = load("userInfo")
  const token = userInfo?.token
  const SPAN_LIST = [
    2, 4, 3, 5, 5, 5,
  ]

  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || isGetMetaInfoLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={mID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider orientation="left">一、个人基本情况</Divider>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                label="姓名"
                initialValue={data?.name}
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"sex"}
                label="性别"
                initialValue={data?.sex}
                rules={[requiredRule]}
              >
                <Select
                  options={[{ value: "男", label: "男" }, { value: "女", label: "女" }]}
                  placeholder="请选择性别"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.education}
                name={"education"}
                label="学历"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.birthday ? dayjs(data?.birthday, "YYYY-MM-DD") : ""}
                name={"birthday"}
                label="出生年月"
                rules={[requiredRule]}
              >
                <DatePicker format={"YYYY-MM-DD"} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.position}
                name={"position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.work_time}
                name={"work_time"}
                label="任职时间"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.job_title}
                name={"job_title"}
                label="职称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.political_background}
                name={"political_background"}
                label="政治面貌"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.tel}
                name={"tel"}
                label="手机号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.id_card}
                name={"id_card"}
                label="身份证号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.contract}
                name={"contract"}
                label="联系人姓名"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.phone}
                name={"phone"}
                label="联系电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={companyName}
                name={"company_name"}
                label="单位名称"
                rules={[requiredRule]}
              >
                <Input readOnly type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.main_project_level}
                name={"main_project_level"}
                label="企业资质"
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={main_project_level}
                  placeholder="请选择企业资质"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={addressWork}
                name={"company_address"}
                label="单位地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业类型"
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={application_type}
                  placeholder="请选择企业类型"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"work_resume"}
                initialValue={data?.work_resume}
                label="个人简历（参加工作算起）">
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award_last_year_personally"}
                initialValue={data?.award_last_year_personally}
                label={`申报人${year - 1}年获得的荣誉：`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"award_this_year_personally"}
                initialValue={data?.award_this_year_personally}
                label={`申报人${year}年获得的荣誉：`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">二、所在企业主要经济指标</Divider>
          <Row className="mt-8" gutter={[16, 0]}>
            <Col span={SPAN_LIST[0]}><b>序号</b></Col>
            <Col span={SPAN_LIST[1]}><b>项目</b></Col>
            <Col span={SPAN_LIST[2]}><b>单位</b></Col>
            <Col span={SPAN_LIST[3]}><b>{year - 1}</b></Col>
            <Col span={SPAN_LIST[4]}><b>{year}</b></Col>
            <Col span={SPAN_LIST[5]}><b>备注</b></Col>
          </Row>
          <Row className="mt-8" gutter={[16, 0]}>
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>1</Col>
            <Col span={SPAN_LIST[1]}>企业总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                initialValue={data?.item1_last_year}
                name={"item1_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                initialValue={data?.item1_this_year}
                name={"item1_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item1_remark}
                name={"item1_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}

            {/* start loop */}
            <Col span={SPAN_LIST[0]}>2</Col>
            <Col span={SPAN_LIST[1]}>市政总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                initialValue={data?.item2_last_year}
                name={"item2_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                initialValue={data?.item2_this_year}
                name={"item2_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item2_remark}
                name={"item2_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>3</Col>
            <Col span={SPAN_LIST[1]}>园林总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_last_year}
                name={"item3_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_this_year}
                name={"item3_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item3_remark}
                name={"item3_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>4</Col>
            <Col span={SPAN_LIST[1]}>市外完成产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_last_year}
                name={"item4_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_this_year}
                name={"item4_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item4_remark}
                name={"item4_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>5</Col>
            <Col span={SPAN_LIST[1]}>省外完成产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_last_year"}
                initialValue={data?.item5_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_this_year"}
                initialValue={data?.item5_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                name={"item5_remark"}
                initialValue={data?.item5_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>6</Col>
            <Col span={SPAN_LIST[1]}>上缴税金</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_last_year"}
                initialValue={data?.item6_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_this_year"}
                initialValue={data?.item6_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                name={"item6_remark"}
                initialValue={data?.item6_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            <Col span={24}>
              <b>说明：企业填写统计数字时要以企业统计年报表（或快报）为依据，真实准确，实事求是，不得弄虚作假。仅市政园林企业需要填报市政及园林产值。</b>
            </Col>
          </Row>
          <Divider className="pt-10" orientation="left">三、企业获奖情况</Divider>
          <Row>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"major_performance_enterprise_last_year"}
                initialValue={data?.major_performance_enterprise_last_year}
                label={`${year - 1}年企业主要荣誉：`}>
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"major_performance_enterprise_this_year"}
                initialValue={data?.major_performance_enterprise_this_year}
                label={`${year}年企业主要荣誉：`}>
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left"></Divider>

          <Form.Item
            rules={[requiredRule]}
            initialValue={id ? data?.enterprise_opinion : "同意上报"}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="请上传PDF格式的文件，按照《申报材料装订顺序》的要求编写"
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              console.log("@664", rst);
              return rst
            }}
            rules={[requiredFileRule]}
          >
            <Upload
              fileList={fileList}
              onChange={(info) => {
                console.log("@637", info);
                const fileList = info.fileList || []
                if (fileList && fileList.length > 0) {
                  setFileList([...info.fileList]);
                } else {
                  setFileList(null)
                }
              }}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} 不是PDF文件`)
                  return Upload.LIST_IGNORE
                }
                return false
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="photo"
            label="电子照片"
            extra="请使用1寸照片，宽：300px，高：450px"
            rules={[requiredPhotoRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.photoList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              return rst
            }}
          >
            <Upload
              name="photo"
              action={upload_photo_url}
              headers={{ Authorization: `Token ${token}`, }}
              fileList={photoList}
              onChange={({ fileList: newFileList }) => {
                return setPhotoList(newFileList)
              }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error(`${file.name} 不是图片文件`)
                  return Upload.LIST_IGNORE
                }
              }}
              maxCount={1}
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              onPreview={() => { console.log(photoList); return false }}
            >
              {photoList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            className="text-center"
          >
            <Space size={"large"}>
              <Button size="large" onClick={() => {
                setRequiredRule({ ...requiredRule, required: false })
                setRequiredFileRule({ ...requiredFileRule, required: false })
                setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
                form.setFieldValue("status", 0)
                setTimeout(() => {
                  form.submit()
                }, 100)
              }} loading={updateIsLoading || addIsLoading} >
                保存
              </Button>
              <Popconfirm
                title="提醒"
                description="提交后表单无法修改，您确认要提交吗？"
                onConfirm={() => {
                  setPhotoAndAttachedRequired()
                  form.setFieldValue("status", 1)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                }}
                okText="确定"
                cancelText="取消"
              >

                <Button size="large" loading={updateIsLoading || addIsLoading} type="primary">提交</Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form >
      }
    </ContentWrap >
  )
}

export default SuccessfulEntrepreneurAdd
