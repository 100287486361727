import { useEffect, useState } from "react"
import ContentWrap from "../components/ContentHead"
import { load } from "react-cookies";
import { BASE_URL, useGetUserQuery } from "../features/api/userAPI";
import { Button, Image } from "antd";

const Cert = () => {
  const breadItmes = [
    { title: "企业信息" },
    { title: "会员证书" },
  ]
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { data } = useGetUserQuery()

  const userInfo = load("userInfo")
  const token = userInfo?.token
  const downloadUrl = `${BASE_URL}/api/members/${data?.id}/cert/`
  useEffect(() => {
    if (!imageUrl) {

      const fetchImage = async () => {
        const response = await fetch(downloadUrl, {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: 'blob',
        })
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setImageUrl(objectUrl);
        setIsLoading(false);
      };
      fetchImage()
      return () => {
        URL.revokeObjectURL(imageUrl);
      }
    }
  }, [imageUrl, downloadUrl, token])
  const onCertDownload = async () => {
    const result = await fetch(imageUrl)
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `电子证书 - ${data?.name}.png`
    link.href = href
    link.click()
    URL.revokeObjectURL(href);
  }
  return (
    <ContentWrap breadItems={breadItmes}>
      <div style={{ maxWidth: 900 }}>
        <Button
          disabled={isLoading}
          onClick={onCertDownload}
          className="mb-5" type="primary">下载证书</Button>
        <Image
          src={imageUrl}
          preview={false}
        />
      </div>
    </ContentWrap>
  )
}
export default Cert
