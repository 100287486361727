import React from "react"
import { useGetUserQuery, userApiSlice } from "../features/api/userAPI"
import { useDispatch } from "react-redux"
import { cleanupUserToken } from "../features/user/userSlice"
import { useNavigate } from "react-router-dom"
import { remove } from "react-cookies"
import { SESSION_LOGIN_TIME, SESSION_USER_INFO } from "../container/RequireUser"
const Welcome = () => {
  const { data } = useGetUserQuery()
  const name = data?.name
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onLogoutClick = () => {
    dispatch(cleanupUserToken())
    remove(SESSION_LOGIN_TIME)
    remove(SESSION_USER_INFO)
    dispatch(userApiSlice.util.resetApiState())
  }
  return (
    <div className="flex">
      欢迎！{name}
      <div className="px-1">
        /
      </div>
      <div className="cursor-pointer" onClick={() => {

        navigate("/changepass")
      }}>
        修改密码
      </div>
      <div className="px-1">
        /
      </div>
      <div onClick={onLogoutClick} className="cursor-pointer">
        注销
      </div>
    </div>
  )
}
export default Welcome
