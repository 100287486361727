import { Table, Space, Button, Tag } from "antd";
import React from "react";
import { BASE_URL, useGetExcellentProjectmanagerQuery, useGetEvaluateplanQuery } from "../../features/api/userAPI";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FormOutlined, ReloadOutlined, } from "@ant-design/icons";
import { load } from "react-cookies";
import ContentWrap from "../../components/ContentHead";

const ExcellentProjectmanager = () => {
  const columns = [
    { title: "申请年份", dataIndex: "year", key: "year" },
    { title: "姓名", dataIndex: "name", key: "name" },
    { title: "联系电话", dataIndex: "phone", key: "phone" },
    { title: "企业类型", dataIndex: "application_type", key: "application_type" },
    { title: "职务", dataIndex: "position", key: "position" },
    {
      title: "结束日期", key: "end_date", render: (_, { year }) => {
        return <div>{rst?.end_date}</div>
      }
    },
    {
      title: "状态", key: "status", render: (_, { status, status_display, year }) => {
        if (rst?.year !== year) {
          return <Tag>已结束</Tag>
        } else if (!isEventStart) {
          return <Tag>{rst?.status}</Tag>
        } else {
          if (status === 0) {
            return <Tag color="processing">{status_display}</Tag>
          } else if (status === 1) {
            return <Tag color="success">{status_display}</Tag>
          } else {
            return <Tag>{status_display}</Tag>
          }
        }
      }
    },
    {
      title: "操作", key: "action", render: (_, { name, status, id, year }) => {
        if (status === 0 && isEventStart && rst?.year === year) {
          return (
            <Space>
              <Link to={`change/${id}`} state={year}>
                修改
              </Link>
            </Space>
          )
        } else if (status === 1) {
          return (
            <Space>
              <Link onClick={async () => {
                const downloadUrl = `${BASE_URL}/api/excellentprojectmanager/${id}/pdf/`
                const userInfo = load("userInfo")
                const token = userInfo?.token
                const result = await fetch(downloadUrl, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                });
                const blob = await result.blob();
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = `优秀项目经理（建造师）申请表-${name}.pdf`
                link.href = href
                link.click()
                URL.revokeObjectURL(href);
              }}
                target="_self"
                rel="noreferrer">
                下载申请表
              </Link>

            </Space>
          )

        }
      }
    },
  ]
  const { data: evaluatePlanData, isLoading: evaluatePlanDataLoading, isError, error, refetch } = useGetEvaluateplanQuery({ type: 3 })
  const rst = evaluatePlanData?.results[0]
  const { data, isLoading } = useGetExcellentProjectmanagerQuery()
  const errorStatus = error?.status
  const navigate = useNavigate()
  const isEventStart = rst?.status === "进行中.."
  const showRefresh = rst?.status !== "进行中.."
  if (isError && errorStatus === 401) {
    return (
      <Navigate to='/login' replace />
    )
  } else {
    const dataSource = data?.results
    return (
      <ContentWrap breadItems={[{ title: "评优评先" }, { title: "优秀项目经理" }]}>
        <Space size={"middle"} className="mb-4">
          {isEventStart &&
            <Button onClick={() => {
              navigate("/excellentprojectmanager/add", { state: { year: rst.year } })
            }} type="primary" icon={<FormOutlined />} size="middle">
              新增申请
            </Button>}
          {showRefresh &&
            <Button loading={evaluatePlanDataLoading} onClick={() => refetch()} icon={<ReloadOutlined />} size="middle">
              未开始
            </Button>
          }
        </Space>
        <Table
          loading={isLoading}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
        />
      </ContentWrap>
    )
  }
}

export default ExcellentProjectmanager
