import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  message,
} from 'antd';
import { useGetUserQuery, useUpdateMemberMutation } from "../features/api/userAPI";
import ContentWrap from "../components/ContentHead";
const { Option } = Select;

function Home() {
  const { isLoading, data } = useGetUserQuery()
  const [editMode, setEditMode] = useState(false)
  const [updateMember, { isLoading: updateIsLoading }] = useUpdateMemberMutation()
  const fields = data?.fields

  const onFinish = (values) => {
    updateMember({ values }).then((result) => {
      const status = result?.error?.status
      setEditMode(false)
      if (status === 200) {
        message.success("修改成功")
      } else if (status === 401) {
        message.error("登录超时，请重新登录")
      }
    })
  };

  const getRenderWidget = ({ widgetName, choices, valueDisplay }) => {
    if (widgetName === 'TextInput') {
      return <Input type="text1" className="ant-form-text" />
    } else if (widgetName === 'NumberInput') {
      return <InputNumber className="ant-form-text" />
    } else if (widgetName === 'Select') {
      return (<Select placeholder="Please select a country">
        {choices.map(([opID, opName]) => <Option key={opID} value={opID}>{opName}</Option>)}
      </Select>)
    } else {
      return <span className="ant-form-text">{valueDisplay}</span>
    }
  }
  if (isLoading) {
    return <>加载中...</>
  } else {
    return (
      <ContentWrap breadItems={[
        { title: "企业信息" },
        { title: "基本信息" }
      ]}>
        <div className="relative">
          {!editMode && (
            <Button onClick={() => { setEditMode(true) }} type="primary button1" className="absolute right-3" >修改信息</Button>
          )}
        </div>

        <Form
          className="mt-2"
          name="validate_other"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          style={{
            maxWidth: 800,
          }}
        >
          {fields?.map((obj, index) => {
            const label = obj?.label
            const value = obj?.value
            const name = obj?.name
            const readonly = obj?.readonly
            const widgetName = obj?.widget
            const choices = obj?.choices
            const valueDisplay = obj?.value_display
            const hidden = obj?.hidden
            if (!editMode || readonly) {
              return (
                <Form.Item hidden={hidden} label={label} key={index}>
                  <span className="ant-form-text">{valueDisplay}</span>
                </Form.Item>
              )
            } else {
              const widget = getRenderWidget({ widgetName, valueDisplay, choices })
              return (
                <Form.Item hidden={hidden} initialValue={value} name={name} label={label} key={index}>
                  {widget}
                </Form.Item>
              )
            }
          })}
          {editMode &&
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 4,
              }}
            >
              <Space size={"middle"}>
                <Button loading={updateIsLoading} type="primary" htmlType="submit">
                  提交
                </Button>
                <Button loading={updateIsLoading} onClick={() => { setEditMode(false) }}>取消</Button>
              </Space>
            </Form.Item>}
        </Form >
      </ContentWrap>
    )
  }
}

export default Home
