import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice'
import { userApiSlice } from '../features/api/userAPI';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    // [companyApiSlice.reducerPath]: companyApiSlice.reducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(userApiSlice.middleware)
  // .concat(companyApiSlice.middleware)
});
