import React, { useState } from 'react';
import { UserOutlined, FormOutlined, LaptopOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import Welcome from '../components/Welcome';
import { useSelector } from 'react-redux';
const { Header, Sider } = Layout;
const Main = () => {
  const themeToken = theme.useToken()
  const {
    token: { colorBgContainer, colorBgHead },
  } = themeToken;
  const navigate = useNavigate()
  const webTitle = useSelector(state => state.user.title)
  const [currentMenu, setCurrentMenu] = useState(["11", "1"])


  const items2 = [
    {
      key: "1", label: "企业信息", icon: React.createElement(UserOutlined), children: [
        { key: "11", label: "基本信息", link: "/" },
        { key: "12", label: "修改密码", link: "/changepass" }
      ]
    },
    { key: "3", label: "会员通讯录", icon: React.createElement(LaptopOutlined), link: "/members" },
    {
      key: "2", label: "评优评先", icon: React.createElement(FormOutlined), children: [
        {
          key: "21", label: "先进企业", link: "/advancedenterprise", unders: [
            { key: "211", label: "增加" },
            { key: "212", label: "修改" }
          ]
        },
        {
          key: "22", label: "优秀企业家", link: "/successfulentrepreneur", unders: [
            { key: "221", label: "增加" },
            { key: "222", label: "修改" }
          ]
        },
        {
          key: "23", label: "优秀项目经理（建造师）", link: "/excellentprojectmanager", unders: [
            { key: "231", label: "增加" },
            { key: "232", label: "修改" }
          ]
        },
      ]
    }]
  // const otherBreads = [...currentMenu].reverse().map((key, index) => {
  //   let title = ""
  //   if (index === 0) {
  //     const { label } = items2.find((item1) => item1.key === key)
  //     title = label
  //   } else if (index === 1) {
  //     const parentKey = key[0]
  //     const { children } = items2.find((item1) => item1.key === parentKey)
  //     const { label } = children.find(itemInner => itemInner.key === key)
  //     title = label
  //   }
  //   return {
  //     title: title,
  //   }
  // })
  // const breads = [
  //   { title: "首页" },
  //   ...otherBreads
  // ]

  return (
    <Layout className='h-screen' >
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          background: colorBgHead
        }}
      >
        <div className="text-white font-semibold text-lg">{webTitle}</div>
        <div className="ml-auto text-white text-sm font-semibold">
          <Welcome />
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            selectedKeys={currentMenu}
            mode="inline"
            defaultOpenKeys={["1"]}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={items2}
            onClick={({ keyPath, key }) => {
              if (keyPath.length === 1) {
                const selectedItem = items2.find(item => item.key === key)
                const link = selectedItem?.link
                if (link) {
                  navigate(link)
                }
              } else if (keyPath.length === 2) {
                const parentKey = key[0]
                const { children, key: key1 } = items2.find((item1) => item1.key === parentKey)
                const selectedItem = children.find(itemInner => itemInner.key === key)
                const link = selectedItem?.link
                const key2 = selectedItem?.key
                if (link) {
                  setCurrentMenu([key2, key1])
                  navigate(link)
                }
              }

            }}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
            items={breads}
          >
          </Breadcrumb> */}
          {/*<Content
            style={{
              padding: 24,
              margin: 0,
              height: 280,
              background: colorBgContainer,
              overflow: 'hidden',
            }}
          >
            <div className="h-full overflow-y-auto">  */}
          <Outlet />
          {/* </div>
      </Content> */}
        </Layout>
      </Layout >
    </Layout >
  );
};
export default Main;
