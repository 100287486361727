import { Select } from "antd"
import React from "react"
import { useGetAreaQuery } from "../features/api/userAPI"
import { useDispatch } from "react-redux"
import { setMemberSearchArea } from "../features/user/userSlice"
const Area = ({ className }) => {
  const { loading, data } = useGetAreaQuery()
  const optionData = data?.results
  const dispatch = useDispatch()
  return (
    <Select
      maxTagCount={2}
      maxTagTextLength={4}
      size="large"
      mode="multiple"
      allowClear
      placeholder="请选择区域"
      className={className}
      loading={loading}
      options={optionData}
      onChange={(value) => {
        dispatch(setMemberSearchArea({ area: value }))
      }}
    />
  )
}
export default Area
