import { Input, Form, message } from "antd"
import FormItem from "antd/es/form/FormItem"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import {
  BASE_URL,
  useUserLoginMutation,
} from "../features/api/userAPI"
import { setUserToken, cleanupUserToken } from "../features/user/userSlice"
import { remove, save } from "react-cookies"
import { SESSION_LOGIN_TIME, SESSION_USER_INFO } from "./RequireUser"
import { LockOutlined, UserOutlined } from "@ant-design/icons"

const Login = () => {

  const [loginStatus, setLoginStatus] = useState('')
  const [error, setError] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userLogin] = useUserLoginMutation()

  const [form] = Form.useForm()

  const onFinish = async (value) => {
    remove(SESSION_USER_INFO)
    remove(SESSION_LOGIN_TIME)
    dispatch(cleanupUserToken())
    const alldata = await userLogin(value)
    const { data, error } = alldata
    if (error) {
      setError("用户名或密码错误")
      setLoginStatus("error")
    } else {
      setLoginStatus("")
      setError("")
      const { username } = form.getFieldValue()
      const { token } = data
      save(SESSION_USER_INFO, { username, token })
      save(SESSION_LOGIN_TIME, new Date().getTime())
      await dispatch(setUserToken({ token, username }))
      navigate("/", { replace: true })
      message.success("登录成功！")
    }
  }
  return (
    <div
      className="h-screen flex bg-cover flex-col"
      style={{
        backgroundImage: `url(${BASE_URL}/static/imgs/login-bg.png)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >

      <div
        className="m-auto w-[450px] min-h-[500px] "
      >
        <div className="w-full h-full bg-white rounded-[10px] mt-[-20px]">
          <div className="mx-auto w-[336px] pt-[26px] pb-[50px]">

            <img
              className="w-20 h-[82.56px] mx-auto"
              src={`${BASE_URL}/static/imgs/qzsz-logo.png`}
              alt="衢州市政公用行业协会logo"
            />

            <div className="text-center mt-[21px]"><span className="text-zinc-800 text-xl font-semibold font-['PingFang SC'] leading-relaxed">衢州市市政公用行业协会<br /></span><span className="text-neutral-500 text-sm font-normal font-['PingFang SC'] leading-relaxed">会员管理服务平台</span></div>
            <Form
              form={form}
              name="login"
              layout="vertical"
              onFinish={onFinish}
            >
              <FormItem
                className="mt-[51px] mb-0"
                validateStatus={loginStatus}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入统一社会信用代码"
                  }
                ]}>
                <Input
                  prefix={<UserOutlined className="text-neutral-500" />}
                  autoComplete="username"
                  placeholder="请输入统一社会信用代码"
                  className="h-[46px] bg-white rounded-[5px] border border-neutral-500"
                />
              </FormItem>
              <FormItem
                className="mb-0"
                validateStatus={loginStatus}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码"
                  }
                ]}>
                <Input.Password
                  prefix={<LockOutlined className="text-neutral-500" />}
                  placeholder="请输入密码"
                  className="h-[46px] bg-white rounded-[5px] border border-neutral-500 mt-[20px]"
                  autoComplete="current-password" />
              </FormItem>

              {error && (<div className="ml-6 text-red-500">{error}</div>)}

              <FormItem
                style={{ textAlign: "center" }}
                className="mt-[55px]"
              >
                <button
                  className="w-full h-[46px] bg-red-600 rounded-[5px] text-white text-base font-normal font-['PingFang SC'] hover:bg-red-800"
                  type="submit"
                >提交</button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div className="mx-auto flex gap-[29px]  mb-[28px] text-[13px] font-normal font-['PingFang SC']">
        <div className="text-zinc-800  ">电话: 0570 - 8261599</div>
        <div className="text-zinc-800 ">地址: 衢州市荷花三路34号三楼</div>
        <a href="http://beian.miit.gov.cn/" target="_blank" className="text-zinc-800 " rel="noreferrer">
          浙ICP备14008336号
        </a>
        <a href="https://www.zhikaiapp.com" target="_blank" className="text-zinc-800 " rel="noreferrer" title="衢州智开信息科技有限公司">技术支持：衢州智开信息科技有限公司</a>
      </div>
    </div >
  )
}
export default Login
