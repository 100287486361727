import { Breadcrumb } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Link } from "react-router-dom";

const ContentWrap = ({ children, breadItems }) => {
  return (<>
    <Breadcrumb
      style={{ margin: '16px 0', }}
      items={breadItems}
      itemRender={(item, params, items, paths) => {
        if (item.path) {
          return <Link to={item.path}>{item.title}</Link>
        } else {
          return <span>{item.title}</span>
        }
      }} />
    <Content
      style={{
        padding: 24,
        margin: 0,
        height: 280,
        overflow: 'hidden',
        backgroundColor: 'white'
      }}
    >
      <div className="h-full overflow-y-auto">
        {children}
      </div>
    </Content>
  </>)
}
export default ContentWrap
