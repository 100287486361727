import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, DatePicker } from "antd";
import React, { useState } from "react";
import { useGetAdvancedEnterpriseDetailQuery, useGetUserQuery, useAddAdvancedEnterpriseMutation, useUpdateAdvancedEnterpriseMutation, useGetMetaInfoQuery } from "../../features/api/userAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
const { Title } = Typography
const AdvancedEnterpriseAdd = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()
  const { data: member } = useGetUserQuery()
  const { data, isLoading } = useGetAdvancedEnterpriseDetailQuery({ id })

  const [addAdvancedEnterprise, { isLoading: addIsLoading }] = useAddAdvancedEnterpriseMutation()
  const [form] = useForm()
  const [updateAdvancedEnterprise, { isLoading: updateIsLoading }] = useUpdateAdvancedEnterpriseMutation()
  const { data: metaData, isLoading: isGetMetaInfoLoading } = useGetMetaInfoQuery()
  const [fileList, setFileList] = useState([])
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  const NOEditRule = [{ required: true, message: "这个字段是必须的" }]
  useEffect(() => {
    const attached = data?.attached
    if (attached) {
      const fileList = [{ uid: -1, name: data.attached_name, status: 'done', url: attached }]
      setFileList(fileList);
    }
  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "先进企业", path: "/advancedenterprise" },
    { title: id ? "修改" : "新增" }
  ]
  if (data?.status === 1) {
    navigate("/advancedenterprise", { replace: true })
  }

  const onFinish = (fieldsValue) => {
    const birthday = fieldsValue['build_time'] ? fieldsValue['build_time'].format('YYYY-MM-DD') : ""
    const values = {
      ...fieldsValue,
      'build_time': birthday,
    }
    const formData = new FormData()
    for (var key in values) {
      const value = values[key] || ""
      formData.append(key, value)
    }
    const tempUploadFile = values?.tempUploadFile
    if (tempUploadFile && tempUploadFile.fileList.length > 0) {
      formData.append("attached", tempUploadFile.fileList[0].originFileObj)
    }
    const navigate_list = () => {
      if (form.getFieldValue("status") !== 1 && id) {
        setRequiredRule({ ...requiredRule, required: true })
        if (fileList && fileList.length > 0) {
          setRequiredFileRule({ ...requiredFileRule, required: false })
        } else {
          setRequiredFileRule({ ...requiredFileRule, required: true })
        }
      } else {
        navigate("/advancedenterprise", { replace: true })
      }
    }
    if (id) {
      updateAdvancedEnterprise({ formData, id }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    } else {
      addAdvancedEnterprise({ formData }).then((result) => {
        const _id = result?.data?.id
        if (_id) {
          message.success("保存成功")
          navigate_list()
        } else {
          message.error("登录超时，请重新登录")
        }
      })
    }
  };
  const handleUploadChange = (info) => {
    setFileList([...info.fileList]);
  }

  const companyName = id ? data?.name : member.name
  const title = id ? "修改 先进企业申请表" : "新增 先进企业申请表"
  const mID = data?.id
  const memberID = id ? data?.member : member.id
  const status = id ? data?.status : 0
  const addressWork = id ? data?.address_work : member.address_work
  const taxNumber = id ? data?.tax_number : member.tax_number
  const year = id ? data?.year : state.year
  const boss = id ? data?.boss : member.boss
  const bossTel = id ? data?.boss_phone : member.boss_tel
  const SPLIT1 = 16
  const SPLIT2 = 8
  const application_type = metaData?.application_type
  // const main_project = metaData?.main_project
  const main_project_level = metaData?.main_project_level
  const SPAN_LIST = [
    2, 4, 3, 5, 5, 5,
  ]
  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || isGetMetaInfoLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={mID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider orientation="left">一、 企业基本情况</Divider>
          <Row gutter={[16, 0]}>
            <Col span={SPLIT1}>
              <Form.Item
                name={"name"}
                label="企业名称"
                initialValue={companyName}
                rules={NOEditRule}
              >
                <Input readOnly type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPLIT2}>

              <Form.Item
                initialValue={data?.build_time ? dayjs(data?.build_time, "YYYY-MM-DD") : ""}
                name={"build_time"}
                label="成立时间"
                rules={[requiredRule]}
              >
                <DatePicker format={"YYYY-MM-DD"} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={SPLIT1}>
              <Form.Item
                initialValue={addressWork}
                name={"address_work"}
                label="企业地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPLIT2}>
              <Form.Item
                initialValue={data?.phone}
                name={"phone"}
                label="电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={taxNumber}
                name={"tax_number"}
                label="统一社会信用代码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>

            <Col span={SPLIT1}>
              <Form.Item
                initialValue={data?.main_project_level}
                name={"main_project_level"}
                label="主项资质等级"
                rules={NOEditRule}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={main_project_level}
                  placeholder="请选择企业资质"
                />
              </Form.Item>
            </Col>
            <Col span={SPLIT2}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业类型"
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={application_type}
                  placeholder="请选择企业类型"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                initialValue={boss}
                name={"boss"}
                label="法定代表人"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.boss_position}
                name={"boss_position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={bossTel}
                name={"boss_phone"}
                label="手机号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.contract}
                name={"contract"}
                label="联系人"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.contract_position}
                name={"contract_position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.contract_phone}
                name={"contract_phone"}
                label="手机号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>




          </Row>
          <Divider orientation="left">二、 企业主要经济指标</Divider>
          <Row gutter={[16, 0]}>
            <Col span={SPAN_LIST[0]}><b>序号</b></Col>
            <Col span={SPAN_LIST[1]}><b>项目</b></Col>
            <Col span={SPAN_LIST[2]}><b>单位</b></Col>
            <Col span={SPAN_LIST[3]}><b>{year - 1}</b></Col>
            <Col span={SPAN_LIST[4]}><b>{year}</b></Col>
            <Col span={SPAN_LIST[5]}><b>备注</b></Col>
          </Row>
          <Row className="mt-8" gutter={[16, 0]}>
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>1</Col>
            <Col span={SPAN_LIST[1]}>企业总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                initialValue={data?.item1_last_year}
                name={"item1_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                initialValue={data?.item1_this_year}
                name={"item1_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item1_remark}
                name={"item1_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}

            {/* start loop */}
            <Col span={SPAN_LIST[0]}>2</Col>
            <Col span={SPAN_LIST[1]}>市政总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                initialValue={data?.item2_last_year}
                name={"item2_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                initialValue={data?.item2_this_year}
                name={"item2_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item2_remark}
                name={"item2_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>3</Col>
            <Col span={SPAN_LIST[1]}>园林总产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_last_year}
                name={"item3_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_this_year}
                name={"item3_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item3_remark}
                name={"item3_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>4</Col>
            <Col span={SPAN_LIST[1]}>市外完成产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_last_year}
                name={"item4_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_this_year}
                name={"item4_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                initialValue={data?.item4_remark}
                name={"item4_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>5</Col>
            <Col span={SPAN_LIST[1]}>省外完成产值</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_last_year"}
                initialValue={data?.item5_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_this_year"}
                initialValue={data?.item5_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                name={"item5_remark"}
                initialValue={data?.item5_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={SPAN_LIST[0]}>6</Col>
            <Col span={SPAN_LIST[1]}>上缴税金</Col>
            <Col span={SPAN_LIST[2]}>万元</Col>
            <Col span={SPAN_LIST[3]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_last_year"}
                initialValue={data?.item6_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[4]}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_this_year"}
                initialValue={data?.item6_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={SPAN_LIST[5]}>
              <Form.Item
                name={"item6_remark"}
                initialValue={data?.item6_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            <Col span={24}>
              <b>说明：企业填写统计数字时要以企业统计年报表（或快报）为依据，真实准确，实事求是，不得弄虚作假。仅市政园林企业需要填报市政及园林产值。</b>
            </Col>
          </Row>
          <Divider className="pt-10" orientation="left">三、 企业主要业绩介绍</Divider>
          <Form.Item
            className="mt-10"
            rules={[requiredRule]}
            initialValue={data?.award1_last_year}
            labelCol={{
              span: 24,
            }}
            name={"award1_last_year"}
            label={<b>{`${year - 1}获得县级以上政府或部门奖项名称`}</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            className="mt-10"
            rules={[requiredRule]}
            initialValue={data?.award1_this_year}
            labelCol={{
              span: 24,
            }}
            name={"award1_this_year"}
            label={<b>{`${year}获得县级以上政府或部门奖项名称`}</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            className="mt-10"
            rules={[requiredRule]}
            initialValue={data?.award2_last_year}
            labelCol={{
              span: 24,
            }}
            name={"award2_last_year"}
            label={<b>{`${year - 1}获得质量、安全、科技创新等奖项名称`}</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            className="mt-10"
            rules={[requiredRule]}
            initialValue={data?.award2_this_year}
            labelCol={{
              span: 24,
            }}
            name={"award2_this_year"}
            label={<b>{`${year}获得质量、安全、科技创新等奖项名称`}</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            rules={[requiredRule]}
            name={"major_performance_enterprise"}
            initialValue={data?.major_performance_enterprise}
            labelCol={{
              span: 24,
            }}
            help="注：填写企业近两年的基本情况，主要改革措施、先进管理办法、获得其他荣誉等情况，约500字左右。"
            label={<b>企业主要业绩介绍</b>}>
            <TextArea autoSize={{ minRows: 10 }}  ></TextArea>
          </Form.Item>
          <Form.Item
            className="mt-10"
            rules={[requiredRule]}
            initialValue={id ? data?.enterprise_opinion : "同意上报"}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="请上传PDF格式的文件，按照《申报材料装订顺序》的要求编写"
            rules={[requiredFileRule]}
          >
            <Upload
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} 不是PDF文件`)
                  return Upload.LIST_IGNORE
                }
                return false
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            className="text-center"
          >
            <Space size={"large"}>
              <Button size="large" onClick={() => {
                setRequiredRule({ ...requiredRule, required: false })
                setRequiredFileRule({ ...requiredFileRule, required: false })
                form.setFieldValue("status", 0)
                setTimeout(() => {
                  form.submit()
                }, 100)
              }} loading={updateIsLoading || addIsLoading} >
                保存
              </Button>
              <Popconfirm
                title="提醒"
                description="提交后表单无法修改，您确认要提交吗？"
                onConfirm={() => {
                  if (fileList && fileList.length > 0) {
                    setRequiredFileRule({ ...requiredFileRule, required: false })
                  }
                  form.setFieldValue("status", 1)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                }}
                okText="确定"
                cancelText="取消"
              >

                <Button size="large" loading={updateIsLoading || addIsLoading} type="primary">提交</Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form >
      }
    </ContentWrap>
  )
}

export default AdvancedEnterpriseAdd
